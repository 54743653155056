import React from "react";
import "./featureArea.css";

function FeatureArea(props) {
  const items = [
    {
      id: 1,
      header: "grumpy",
      text: "24' x 24'",
      image: "bhs.png",
      alt: "grumpy",
    },
    {
      id: 2,
      header: "sleepy",
      text: "24' x 24'",
      image: "bhs.png",
      alt: "sleepy",
    },
    {
      id: 3,
      header: "snoopy",
      text: "24' x 24'",
      image: "bhs.png",
      alt: "snoopy",
    },
  ];
  return (
    <div className="featureAreaRoot">
      <div className="featureAreaContainer">
        <div className="featureAreaHeader">Artwork</div>
        <div className="featureAreaCards">
          {items.map((item, index) => (
            <div>
              <Card
                header={item.header}
                text={item.text}
                image={item.image}
                alt={item.alt}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeatureArea;

function Card({ header, text, image, alt }) {
  return (
    <div className="cardRoot">
      <div className="cardContainer">
        <div className="cardImageContainer">
          <img className="cardImage" src={image} alt={alt} />
        </div>
        <div className="cardHeader">{header}</div>
        <div className="cardText">{text}</div>
      </div>
    </div>
  );
}
