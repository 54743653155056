// import logo from "./logo.svg";
import FeatureArea from "./feature/featureArea";
import Header from "./header/Header";
// import HeroArea from "./hero/HeroArea";
function App() {
  return (
    <div style={{ margin: 0, padding: 0 }} className="App">
      <Header />
      {/* <HeroArea /> */}
      <FeatureArea />
      {/* black hat studios */}
    </div>
  );
}

export default App;
